import React from "react";
import DanJRProject from "../assests/projects/DanJR.svg";
import DanJRProject1 from "../assests/projects//DanJR.png";
import Greenbite1 from "../assests/projects/Greenbite1.png";
import Greenbite2 from "../assests/projects/Greenbite2.png";
import MyFuse1 from "../assests/projects/MyFuse1.png";
import MyFuse2 from "../assests/projects/MyFuse2.png";
import CourePage1 from "../assests/projects/GUVICouseLandingPage1.png";
import CourePage2 from "../assests/projects/GUVICouseLandingPage2.png";
import VideoPage1 from "../assests/projects/GUVIVideopalayer1.png";
import VideoPage2 from "../assests/projects/GUVIVideopalayer2.png";

export default function UIdesign() {
  function smoothScroll(e) {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href'); 
    const targetElement = document.querySelector(targetId); 
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
    <section className="">
      <div id="experience" className=" text-black py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full md:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 relative">
            <div className="lg:max-w-xl lg:pr-5 relative z-40">
              <p className="flex text-sm uppercase text-g1">Innovative and User-Friendly Interfaces

</p>
              <h2 className="mb-6 max-w-lg text-4xl font-light leading-snug tracking-tight text-g1 sm:text-6xl lg:text-4xl sm:leading-snug">
                <span className="my-1 inline-block border-b-8 border-g4  px-4 font-bold text-g4 animate__animated animate__flash">
                  UI Design
                </span>{" "}
                
              </h2>
              <p className="text-base text-gray-900">
              My UI design projects focus on creating visually appealing and intuitive interfaces that enhance user experiences. Explore my work on various applications and websites, each designed to meet specific user needs and business goals.
              </p>
              <div className="mt-10 flex flex-col items-center md:flex-row">
                <a
                  href="#contact"
                  aria-label="Get Connect Now"
                  onClick={(e) => smoothScroll(e)}

                  className="group inline-flex items-center font-semibold text-g1"
                >
                  Request a work now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-4 h-6 w-6 transition-transform group-hover:translate-x-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3">
            <div className="container mx-auto w-full h-full">
            <div className="mt-5 flex w-full flex-col items-center justify-center gap-2 rounded-lg border border-dashed border-[#8000FF] p-5 shadow-sm sm:flex-row sm:justify-between sm:items-center sm:gap-4">
        <div className="mt-14 relative w-full sm:mt-0">
          <div className="p-6 mb-5">
            <h2 className="font-bold text-2xl mb-4 text-center sm:text-left">Dan JR Briyani Store</h2>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Problem Statement:</span> The popular Tamil YouTuber, Dan JR, needed an intuitive and user-friendly food ordering website to cater to his growing audience.
            </p>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Approach:</span> I collaborated closely with Dan JR to understand his brand and audience. The goal was to create a website that reflects his unique style while providing a seamless ordering experience.
            </p>
            <h3 className="font-bold text-xl mb-2 text-center sm:text-left">Design Process:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-600">
              <li><span className="font-semibold text-gray-500">Research & Analysis:</span> Analyzed competitors and gathered user feedback to identify key features and design elements.</li>
              <li><span className="font-semibold text-gray-500">Wireframing:</span> Created wireframes to outline the structure and layout of the website.</li>
              <li><span className="font-semibold text-gray-500">Mockups:</span> Developed high-fidelity mockups to visualize the design.</li>
              <li><span className="font-semibold text-gray-500">Implementation:</span> Used React JS and Tailwind CSS to bring the design to life.</li>
            </ol>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Final Outcome:</span> The website received positive feedback for its ease of use and appealing design, leading to an increase in online orders.
            </p>
          </div>
          <a
            href="https://www.figma.com/file/O2i8m8dQwE0wGwVFTHIyIE/Guvi?type=design&mode=design&t=1uoaxXFFlklvsimj-1"
            target="_blank"
            rel="noopener noreferrer"
            className="block relative"
          >
            <img
              src={DanJRProject}
              className="w-full rounded-lg"
              alt="Dan JR Briyani Store Homepage"
            />
            <img
              src={DanJRProject1}
              className="w-full rounded-lg absolute top-0 left-0 opacity-0 hover:opacity-100 transition-opacity duration-200"
              alt="Dan JR Briyani Store Detailed View"
              style={{ width: '100%', height: '100%' }}
            />
          </a>
        </div>
      </div>
      <div className="mt-14 relative w-full flex flex-col items-center justify-center gap-2 rounded-lg border border-dashed border-[#8000FF] p-5 shadow-sm">
        <div className="p-6 mb-5">
            <h2 className="font-bold text-2xl mb-4 text-center sm:text-left">Greenbite</h2>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Problem Statement:</span> A fictional organic food delivery service needed an attractive and functional UI to engage users.
            </p>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Approach:</span> Conducted user research and competitive analysis to create a user-centric design.
            </p>
            <h3 className="font-bold text-xl mb-2 text-center sm:text-left">Design Process:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-600">
              <li><span className="font-semibold text-gray-500">Research & Analysis:</span> Identified user needs and preferences through surveys and competitor analysis.</li>
              <li><span className="font-semibold text-gray-500">Wireframing:</span> Developed wireframes to establish the layout and flow.</li>
              <li><span className="font-semibold text-gray-500">Mockups:</span> Created high-fidelity mockups to demonstrate the design aesthetics.</li>
              <li><span className="font-semibold text-gray-500">Implementation:</span> Utilized React JS and Tailwind CSS to build the interface.</li>
            </ol>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Final Outcome:</span> The UI was well-received, providing an engaging and easy-to-use platform for users.
            </p>
          </div>
          <a
            href="https://www.figma.com/design/dIma8LQf84proswVjLVmGw/GreenBite?node-id=98-130&t=GAk5bPmauXqqLUI8-1"
            target="_blank"
            rel="noopener noreferrer"
            className="block relative"
          >
            <img
              src={Greenbite1}
              className="w-full rounded-lg"
              alt="Greenbite"
            />
            <img
              src={Greenbite2}
              className="w-full rounded-lg absolute top-0 left-0 opacity-0 hover:opacity-100 transition-opacity duration-200"
              alt="Greenbite"
            />
          </a>
        </div>
        <div className="mt-14 relative w-full flex flex-col items-center justify-center gap-2 rounded-lg border border-dashed border-[#8000FF] p-5 shadow-sm">
        <div className="p-6 mb-5">
            <h2 className="font-bold text-2xl mb-4 text-center sm:text-left">MyFuse</h2>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Problem Statement:</span> MyFuse needed a job portal website with an attractive design and seamless functionality.
            </p>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Approach:</span> Worked closely with stakeholders to understand requirements and user expectations.
            </p>
            <h3 className="font-bold text-xl mb-2 text-center sm:text-left">Design Process:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-600">
              <li><span className="font-semibold text-gray-500">Research & Analysis:</span> Conducted interviews and gathered user feedback to define key features.</li>
              <li><span className="font-semibold text-gray-500">Wireframing:</span> Created wireframes to outline the website structure.</li>
              <li><span className="font-semibold text-gray-500">Mockups:</span> Designed high-fidelity mockups to visualize the final look.</li>
              <li><span className="font-semibold text-gray-500">Implementation:</span> Developed the website using React JS and Tailwind CSS.</li>
            </ol>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Final Outcome:</span> The job portal received positive feedback for its design and functionality, improving user engagement.
            </p>
          </div>
          <a
            href="https://myfuse-x.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="block relative"
          >
            <img
              src={MyFuse1}
              className="w-full rounded-lg"
              alt="MyFuse"
            />
            <img
              src={MyFuse2}
              className="w-full rounded-lg absolute top-0 left-0 opacity-0 hover:opacity-100 transition-opacity duration-200"
              alt="MyFuse"
            />
          </a>

        </div>

      
      <div className="mt-14 relative w-full flex flex-col items-center justify-center gap-2 rounded-lg border border-dashed border-[#8000FF] p-5 shadow-sm">
      <div className="p-6 mb-5">
            <h2 className="font-bold text-2xl mb-4 text-center sm:text-left">GUVI Course Landing Page</h2>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Problem Statement:</span> GUVI needed a redesigned course landing page to enhance user engagement and navigation.
            </p>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Approach:</span> Analyzed user behavior and gathered feedback to inform the design process.
            </p>
            <h3 className="font-bold text-xl mb-2 text-center sm:text-left">Design Process:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-600">
              <li><span className="font-semibold text-gray-500">Research & Analysis:</span> Conducted user testing to understand pain points.</li>
              <li><span className="font-semibold text-gray-500">Wireframing:</span> Developed wireframes to outline the new layout.</li>
              <li><span className="font-semibold text-gray-500">Mockups:</span> Created high-fidelity mockups to visualize the redesign.</li>
              <li><span className="font-semibold text-gray-500">Implementation:</span> Utilized React JS and Tailwind CSS to develop the page.</li>
            </ol>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Final Outcome:</span> The redesigned page improved user engagement and course enrollment.
            </p>
          </div>
        <a
          href="https://www.figma.com/design/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?node-id=345-3442&t=0xgYejyRDBtOHENy-1"
          target="_blank"
          rel="noopener noreferrer"
          className="block relative"
        >
          <img
            src={CourePage1}
            className="w-full rounded-lg"
            alt="GUVI Course Landing Page"
          />
          <img
            src={CourePage2}
            className="w-full rounded-lg absolute top-0 left-0 opacity-0 hover:opacity-100 transition-opacity duration-200"
            alt="GUVI Course Landing Page"
          />
        </a>

      </div>
      
      <div className="mt-14 relative w-full flex flex-col items-center justify-center gap-2 rounded-lg border border-dashed border-[#8000FF] p-5 shadow-sm">
      <div className="p-6 mb-5">
            <h2 className="font-bold text-2xl mb-4 text-center sm:text-left">GUVI Video Player Page</h2>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Problem Statement:</span> GUVI needed a redesign of their video player page to improve usability and enhance the viewing experience.
            </p>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Approach:</span> Gathered user feedback and analyzed competitor designs to inform the redesign process.
            </p>
            <h3 className="font-bold text-xl mb-2 text-center sm:text-left">Design Process:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-600">
              <li><span className="font-semibold text-gray-500">Research & Analysis:</span> Identified user pain points through feedback and testing.</li>
              <li><span className="font-semibold text-gray-500">Wireframing:</span> Created wireframes to layout the new design.</li>
              <li><span className="font-semibold text-gray-500">Mockups:</span> Developed high-fidelity mockups to visualize the changes.</li>
              <li><span className="font-semibold text-gray-500">Implementation:</span> Used React JS and Tailwind CSS to implement the redesign.</li>
            </ol>
            <p className="font-semibold text-gray-500 mb-2 text-center sm:text-left">
              <span className="text-black">Final Outcome:</span> The new video player page design enhanced the viewing experience and usability, leading to increased user satisfaction.
            </p>
          </div>
        <a
          href="https://www.figma.com/design/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?node-id=513-2&t=0xgYejyRDBtOHENy-1"
          target="_blank"
          rel="noopener noreferrer"
          className="block relative"
        >
          <img
            src={VideoPage1}
            className="w-full rounded-lg"
            alt="GUVI Video Player Page"
          />
          <img
            src={VideoPage2}
            className="w-full rounded-lg absolute top-0 left-0 opacity-0 hover:opacity-100 transition-opacity duration-200"
            alt="GUVI Video Player Page"
          />
        </a>

      </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      

    </>
  );
}
