import React from "react";
import SocialMedia from "./SocialMedia";
import HeroIcon from "../assests/Hero.png";
import HeroBG from "../assests/HeroBG.png";

const Hero = () => {
  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    const targetId = event.currentTarget.getAttribute("href"); // Get the href attribute of the clicked link
    const targetElement = document.querySelector(targetId); // Select the target element based on the href value

    if (targetElement) {
      // If the target element exists, scroll to it smoothly
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="home" className="relative bg-[#3f3d56] h-screen text-white overflow-hidden rounded-lg ml-2 mr-2">
      <div className="absolute inset-0">
        <img src={HeroBG} className="object-cover object-center w-full h-full" alt="Hero Background" />
        <div className="absolute inset-0 bg-black opacity-50" />
      </div>
      <div className="relative z-10 flex flex-col justify-center items-center h-full text-center">
        <h1 className="text-5xl font-bold leading-tight mb-4">
          Explore My Creative Universe!
        </h1>
        <p className="text-lg text-gray-200 mb-8 drop-shadow-2xl">
          Crafting Intuitive Digital Experiences
        </p>
        <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
          <a
            href="#contact"
            onClick={handleLinkClick}
            className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-gradient-to-r from-[#FF8660] to-[#8000FF] rounded-2xl sm:w-auto sm:mb-0 hover:bg-gradient-to-r hover:from-[#8000FF] hover:to-[#FF8660] transition-all duration-1000 ease-in-out"
          >
            Connect with Me
            <svg
    class="w-4 h-4 ml-1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
      clip-rule="evenodd"
    ></path>
  </svg>
          </a>
          <a
            href="#portfolio"
            onClick={handleLinkClick}
            className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg bg-gray-100 rounded-2xl sm:w-auto sm:mb-0 text-black"
          >
            Explore My Works
            <svg
              class="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;