import React from 'react'
import achievement from '../assests/achievement.svg';
export default function Achievement() {
  return (
    <>
<div className="py-12 sm:py-24" id='achievements' >
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl text-center">
      <h1 className="text-4xl font-bold tracking-tight text-[#8000FF]">
        My Achievements
      </h1>
      <p className="mt-6 text-lg leading-8 text-gray-600">
      Here are some of my achievements that I have accomplished in my career. I am proud of my accomplishments and look forward to achieving more in the future. 
      </p>

    </div>
    <div className="mt-10 flow-root sm:mt-20">
      <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
        {/* Photo by '@tvick' on Unsplash */}
        <img
          src={achievement}
          width={2432}
          height={1442}
          className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
        />
      </div>
    </div>
  </div>
</div>
    </>
  )
}
