import React, { useState, useRef } from "react";
import WebDevProjects from "./WebDevProjects";
import PPTs from "./PPTs";
import Videos from "./Videos";
import Logos from "./Logos";
import UIdesign from "./UIdesign";
import UXdesign from "./UXdesign";
import GraphicDesign from "./GraphicDesign";
import magic from "../assests/services/magic.gif";

const servicesData = {
  features: [
    {
      title: "UI Design",
      alt: "UI Design illustration",
      component: <UIdesign />,
    },
    {
      title: "UX Design",
      alt: "UX Design illustration",
      component: <UXdesign />,
    },
    {
      title: "Graphic Design",
      alt: "Graphic Design illustration",
      component: <GraphicDesign />,
    },
    {
      title: "Logo Creation",
      alt: "Logo Creation illustration",
      component: <Logos />,
    },
    {
      title: "Video Editing",
      alt: "Video Editing illustration",
      component: <Videos />,
    },
    {
      title: "PPT Design",
      alt: "PPT Design illustration",
      component: <PPTs />,
    },
  ],
};

const defaultOrder = [
  { title: "UI Design", component: <UIdesign /> },
  { title: "UX Design", component: <UXdesign /> },
  { title: "Graphic Design", component: <GraphicDesign /> },
  { title: "Logo Creation", component: <Logos /> },
  { title: "Web Development Projects", component: <WebDevProjects /> },
  { title: "Video Editing", component: <Videos /> },
  { title: "PPT Design", component: <PPTs /> },
];

const PortfolioSection = () => {
  const [selectedService, setSelectedService] = useState(null);

  // Initialize refs for each component at the top level of the component
  const uiDesignRef = useRef(null);
  const uxDesignRef = useRef(null);
  const graphicDesignRef = useRef(null);
  const logoCreationRef = useRef(null);
  const webDevProjectsRef = useRef(null);
  const videoEditingRef = useRef(null);
  const pptDesignRef = useRef(null);

  // Create a mapping from title to ref
  const componentRefs = {
    "UI Design": uiDesignRef,
    "UX Design": uxDesignRef,
    "Graphic Design": graphicDesignRef,
    "Logo Creation": logoCreationRef,
    "Web Development Projects": webDevProjectsRef,
    "Video Editing": videoEditingRef,
    "PPT Design": pptDesignRef,
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
    componentRefs[service.title].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const renderedComponents = selectedService
    ? [
        selectedService.component,
        ...defaultOrder
          .filter((item) => item.title !== selectedService.title)
          .map((item) => item.component),
      ]
    : defaultOrder.map((item) => item.component);

    function smoothScroll(e) {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('href'); 
      const targetElement = document.querySelector(targetId); 
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

  return (
    <section className="bg-white" id="portfolio">
      <div className="max-w-screen-xl mx-auto px-4 py-28 gap-12 text-black md:px-8">
        <div className="space-y-5 max-w-4xl mx-auto text-center">
          <h1 className="text-sm text-[#8000FF] font-medium">
            Portfolio Projects
          </h1>
          <h2 className="text-4xl text-black font-extrabold mx-auto md:text-5xl">
            Showcase of My{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#FF8660] to-[#8000FF]">
              Creative Work
            </span>
          </h2>
          <p className="max-w-2xl mx-auto text-gray-800">
            Each project reflects my dedication to crafting high-quality digital
            experiences that are both intuitive and visually appealing.
          </p>
          <div className="items-center justify-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
            <a
              href="#contact"
              onClick={(e) => smoothScroll(e)}

              className="block py-2 px-4 text-white font-medium bg-[#8000FF] duration-150 hover:bg-[#FF8660] active:bg-[#8000FF] rounded-lg shadow-lg hover:shadow-none"
            >
              Request a work
            </a>
            <a
              href="#contact"
              onClick={(e) => smoothScroll(e)}

              className="block py-2 px-4 text-gray-800 hover:text-gray-400 font-medium duration-150 active:bg-gray-800 border rounded-lg"
            >
              Get Connect
            </a>
          </div>
        </div>
        <div className="grid grid-cols-3 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start mt-5">
          {servicesData.features.map((feature, index) => (
            <div key={index} className="relative">
              <button
                aria-label={feature.title}
                onClick={() => handleServiceClick(feature)}
                className="p-4 border border-gray-200 dark:bg-gray-800 dark:border-gray-700 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20 dark:hover:border-cyan-300/30"
              >
                <div className="flex justify-center space-x-4">
                  <img
                    className="w-6 h-6 md:block hidden"
                    src={magic}
                    alt={feature.alt}
                    loading="lazy"
                    width={128}
                    height={128}
                  />
                  <span className="block md:hidden font-medium text-black dark:text-white">
                    {feature.title}
                  </span>
                  <span className="hidden md:block font-medium dark:text-white">
                    {feature.title}
                  </span>
                </div>
              </button>
            </div>
          ))}
        </div>
        {defaultOrder.map((item, index) => (
          <div key={index} ref={componentRefs[item.title]}>
            {item.component}
          </div>
        ))}
      </div>
    </section>
  );
};

export default PortfolioSection;
