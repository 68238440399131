import React from 'react'
import LogoTNSDC from "../assests/projects/LogoForTNSDCpng.png";
import LogoASCL from "../assests/projects/LogoForASCLpng.png";
import LogoNPTEL from "../assests/projects/LogoForNPTELpng.png";

export default function Logos() {
  function smoothScroll(e) {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href'); 
    const targetElement = document.querySelector(targetId); 
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
    <section>
      <div id="experience" className=" text-black py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full md:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 relative">
            <div className="lg:max-w-xl lg:pr-5 relative z-40">
              <p className="flex text-sm uppercase text-g1">Brand Identity Design

</p>
              <h2 className="mb-6 max-w-lg text-4xl font-light leading-snug tracking-tight text-g1 sm:text-6xl lg:text-4xl sm:leading-snug">
                <span className="my-1 inline-block border-b-8 border-g4  px-4 font-bold text-g4 animate__animated animate__flash">
                Logo Creation

                </span>
              
              </h2>
              <p className="text-base text-gray-900">
              My logo creation projects focus on developing strong brand identities through simple yet impactful designs. Each logo is designed to reflect the brand’s values and resonate with its target audience.




              </p>
              <div className="mt-10 flex flex-col items-center md:flex-row">
                <a
                  href="#contact"
                  onClick={(e) => smoothScroll(e)}
                  aria-label="Get Connect Now"
                  className="group inline-flex items-center font-semibold text-g1"
                >
                  Request a Work Now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-4 h-6 w-6 transition-transform group-hover:translate-x-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3">
            <div className="container mx-auto w-full h-full">
            <div className="flex flex-col items-center">

        <div className="mt-14 relative">
          <img src={LogoTNSDC} className="w-full rounded-lg" alt="TNSDC Logo" />
          <p className="mt-2 text-center text-gray-700">
            Developed the logo for the Tamil Nadu Skill Development Corporation (TNSDC), symbolizing its mission to enhance skill development and employment opportunities in Tamil Nadu.
          </p>
        </div>
        <div className="mt-14 relative">
          <img src={LogoASCL} className="w-full rounded-lg" alt="TNSDC Logo" />
          <p className="mt-2 text-center text-gray-700">
          Created the 25th anniversary logo for the Asian School of Cyber Laws, commemorating its legacy and contribution to cyber law education and research.
          </p>
        </div>
        <div className="mt-14 relative">
          <img src={LogoNPTEL} className="w-full rounded-lg" alt="TNSDC Logo" />
          <p className="mt-2 text-center text-gray-700">
          Designed the logo for NPTEL (National Programme on Technology Enhanced Learning), which represents the initiative's commitment to providing high-quality educational resources.
          </p>
        </div>
      </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
