import React from 'react'
import SocialMedia from './SocialMedia'
import logo from '../assests/Hero.png'

export default function Footer() {
  return (
    <section className="bg-[#1f2937]">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8 flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <img src={logo} className="h-8 mr-3 sm:h-9" alt="Mahsok Logo" />
          <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
            Mahsook Designs
          </span>
        </div>
        <p className="mt-8 text-base leading-6 text-center text-gray-200">
          Thank you for taking the time to read my profile. Feel free to contact me if you want to know more about me or if you have any opportunities that may suit my skills and interests.
        </p>
        <SocialMedia />
      </div>
    </section>
  )
}