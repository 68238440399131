import React from "react";
import poster from "../assests/poster.png";

export default function GraphicDesign() {
  function smoothScroll(e) {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href'); 
    const targetElement = document.querySelector(targetId); 
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
      <section className="">
        <div id="experience" className=" text-black py-8">
          <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
            <div className="flex flex-col w-full md:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 relative">
              <div className="lg:max-w-xl lg:pr-5 relative z-40">
                <p className="flex text-sm uppercase text-g1">
                  Visual Storytelling
                </p>
                <h2 className="mb-6 max-w-lg text-4xl font-light leading-snug tracking-tight text-g1 sm:text-6xl lg:text-4xl sm:leading-snug">
                  <span className="my-1 inline-block border-b-8 border-g4  px-4 font-bold text-g4 animate__animated animate__flash">
                    Graphic Design{" "}
                  </span>{" "}
                </h2>
                <p className="text-base text-gray-900">
                  In my graphic design projects, I combine creativity with
                  strategic thinking to produce compelling visuals. From
                  marketing materials to digital graphics, each piece is crafted
                  to communicate the intended message effectively.
                </p>
                <div className="mt-10 flex flex-col items-center md:flex-row">
                  <a
                  href="#contact"
                  onClick={(e) => smoothScroll(e)}
                  aria-label="Get Connect Now"
                    className="group inline-flex items-center font-semibold text-g1"
                  >
                    Request a work now
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-4 h-6 w-6 transition-transform group-hover:translate-x-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="ml-0 md:ml-12 lg:w-2/3">
              <div className="container mx-auto w-full h-full">
                <div class="mt-5 flex w-full flex-col items-center justify-between gap-2 rounded-lg border border-dashed border-[#8000FF]  p-5 shadow-sm sm:flex-row sm:gap-0">
                  <div class="flex flex-col items-center justify-start gap-4 sm:flex-row">
                    <div class="text-center sm:text-left">
                      <p class="font-semibold text-gray-500 mb-2">
                        I created a some of the best posters for my college
                        events.Here are some of them
                      </p>
                      <img src={poster} alt="poster" className="rounded-lg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
