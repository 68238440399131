import React from "react";
import Rocket from "../assests/Rocket.svg";
import Ballon from "../assests/ballon.svg";

const experiences = [
  {
    id: 1,
    company: "NearTreat",
    position: "Co-Founder and CEO",
    duration: "11/2022 - Present",
    description: (
      <>
        Founded NearTreat, a Web application designed to address the problem of
        locating street food vendors and enhancing the street food experience.
        Developed the vision and strategy for the company, leading a team to
        create a unique platform that offers live location tracking and
        real-time availability of street food recipes.
      </>
    ),
  },
  {
    id: 2,
    company: "GUVI Geek Networks, IITM Research Park",
    position: "Design Intern",
    duration: "Mar 2024 - May 2024",
    description: (
      <>
        Secured this internship opportunity at Guvi by winning the Guvi Design
        Hackathon. During the internship, gained comprehensive experience in
        UX/UI design, covering product research, heuristic analysis, user
        research, and interviews. Explored analytic platforms, design systems,
        and landing page optimization. Examined roles of product management and
        design, conducted competitor analysis.
      </>
    ),
  },
  {
    id: 3,
    company: "The Better Tomorrow",
    position: "MERN Stack Intern",
    duration: "6/2023 - 7/2023",
    description: (
      <>
        During my internship at The Better Tomorrow, a prominent web development
        company, I gained hands-on experience working with the MERN stack.
        Actively contributed to front-end development using HTML, CSS,
        JavaScript, and React JS. Gained expertise in back-end development using
        Node JS and Express JS.
      </>
    ),
  },
];

const Experience = () => {
  function smoothScroll(e) {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href'); 
    const targetElement = document.querySelector(targetId); 
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <section className="md:pl-20 bg-gray-800">
      <div id="experience" className="bg-gray-800 text-white py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full md:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 relative">
            <div className="lg:max-w-xl lg:pr-5 relative z-40">
              <p className="flex text-sm uppercase text-g1">Experience</p>
              <h2 className="mb-6 max-w-lg text-4xl font-light leading-snug tracking-tight text-g1 sm:text-6xl lg:text-4xl sm:leading-snug">
                Journey Through{" "}
                <span className="my-1 inline-block border-b-8 border-g4  px-4 font-bold text-g4 animate__animated animate__flash">
                  Design
                </span>{" "}
                &{" "}
                <span className="my-1 inline-block border-b-8 border-g4  px-4 font-bold text-g4 animate__animated animate__flash">
                  Development
                </span>
              </h2>
              <p className="text-base text-gray-200">
                I am a passionate and creative designer and developer with a
                keen eye for detail and a determination to deliver the very
                highest quality. I take great pride in my work, and I always try
                to better myself with every project I work on. I am a fast
                learner, able to pick up new skills and juggle different
                projects with relative ease.
              </p>
              <div className="mt-10 flex flex-col items-center md:flex-row">
                <a
                  href="#contact"
                  onClick={(e) => smoothScroll(e)}

                  aria-label="Get Connect Now"
                  className="group inline-flex items-center font-semibold text-g1"
                >
                  Get Connect Now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-4 h-6 w-6 transition-transform group-hover:translate-x-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3">
            <div className="container mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden p-10 h-full">
                <div
                  className="border-2-2 border-gradient-to-r from-[#FF8660] to-[#8000FF] absolute h-full border"
                  style={{
                    right: "50%",
                    border: "2px solid #9812FF",
                    borderRadius: "1%",
                  }}
                />
                <div
                  className="border-2-2 border-gradient-to-r from-[#FF8660] to-[#8000FF] absolute h-full border"
                  style={{
                    left: "50%",
                    border: "2px solid #9812FF",
                    borderRadius: "1%",
                  }}
                />
                {experiences.map((experience, index) => (
                  <div
                    key={experience.id}
                    className={`mb-8 flex justify-between items-center w-full ${
                      index % 2 === 0
                        ? "flex-row-reverse left-timeline"
                        : "right-timeline"
                    }`}
                  >
                    <div className="order-1 w-5/12" />
                    <div
                      className={`order-1 w-5/12 px-1 py-4 ${
                        index % 2 === 0 ? "text-right" : "text-left"
                      }`}
                    >
                      <p className="mb-3 text-base text-[#FF8660]">
                        {experience.duration}
                      </p>
                      <h4 className="mb-3 font-bold text-lg md:text-2xl text-[#8000FF]">
                        {experience.company}
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-white font-bold">
                        {experience.position}
                      </p>
                      <p className="text-sm md:text-base leading-snug text-gray-200">
                        {experience.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <img
                className="mx-auto -mt-26 md:-mt-16 w-80"
                src={Rocket}
                alt="Decoration"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
