import React from "react";

const Portfolio = () => {
  function smoothScroll(e) {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute("href");
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }
  const cardData = [
    {
      title: "Product Research",
      description:
        "Conducted extensive product research to understand user needs and market trends, providing valuable insights for the development of new features and improvements.",
      link: "https://www.figma.com/design/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?node-id=26-18662&t=ZdOcuf3X0OE02xHm-1",
    },
    {
      title: "Heuristic Analysis in UX",
      description:
        "Performed heuristic analysis to identify usability issues and suggest enhancements, ensuring a seamless and user-friendly experience.",
      link: "https://www.figma.com/design/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?node-id=0-1&t=ZdOcuf3X0OE02xHm-1",
    },
    {
      title: "User Research",
      description:
        "Conducted user research to gather qualitative data, helping to shape user-centered design decisions and improve overall user satisfaction.",
      link: "https://www.figma.com/design/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?node-id=26-18663&t=ZdOcuf3X0OE02xHm-1",
    },
    {
      title: "User Interview",
      description:
        "Led user interviews to collect direct feedback, uncovering pain points and preferences that informed design and development strategies.",
      link: "https://www.figma.com/design/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?node-id=46-10546&t=ZdOcuf3X0OE02xHm-1",
    },
    {
      title: "User Journey Mapping",
      description:
        "Created user journey maps to visualize the end-to-end user experience, identifying key touchpoints and opportunities for improvement.",
      link: "https://www.figma.com/design/3BIOwr2LUn9Qdu7SxrCf1o/Guvi-Tasks?node-id=403-27615&t=ZdOcuf3X0OE02xHm-1",
    },
  ];

  return (
    <section>
      <div id="experience" className="text-black py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full md:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 relative">
            <div className="lg:max-w-xl lg:pr-5 relative z-40">
              <p className="flex text-sm uppercase text-g1">
                Enhancing User Experiences
              </p>
              <h2 className="mb-6 max-w-lg text-4xl font-light leading-snug tracking-tight text-g1 sm:text-6xl lg:text-4xl sm:leading-snug">
                <span className="my-1 inline-block border-b-8 border-g4 px-4 font-bold text-g4 animate__animated animate__flash">
                  UX Design
                </span>
              </h2>
              <p className="text-base text-gray-900">
                Through meticulous research and thoughtful design processes, my
                UX design projects aim to improve user satisfaction and
                usability. Discover how I solve complex problems and create
                seamless experiences for users across different platforms.
              </p>
              <div className="mt-10 flex flex-col items-center md:flex-row">
                <a
                  href="#contact"

                  onClick={(e) => smoothScroll(e)}
                  aria-label="Get Connect Now"
                  className="group inline-flex items-center font-semibold text-g1"
                >
                  Request a Work Now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-4 h-6 w-6 transition-transform group-hover:translate-x-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3">
            <div className="container mx-auto w-full h-full flex flex-wrap justify-between">
              {cardData.map((card, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-lg p-6 m-4 w-full sm:w-1/2 lg:w-1/3"
                >
                  <h3 className="text-2xl font-bold mb-2">{card.title}</h3>
                  <p className="text-gray-700 mb-4">{card.description}</p>
                  <a
                    href={card.link}
                    className="text-blue-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Project
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
