import React from 'react';
import emailjs from 'emailjs-com';
import contact from '../assests/contact.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2n0l56s', 'template_i7yj08g', e.target, 'iD6eSbwQ2NjIhMYRN')
      .then((result) => {
          console.log(result.text);
          toast.success('Message sent successfully');
      }, (error) => {
          console.log(error.text);
        toast.error('An error occurred, Please try again');

      });

    e.target.reset();
  };

  return (
    <main className="py-14 bg-gray-50" id='contact' >
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="max-w-lg mx-auto gap-12 justify-between lg:flex lg:max-w-none">
          <div className="max-w-lg space-y-3">
            <h3 className="text-purple-700 font-semibold">Contact Me</h3>
            <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">Let's Connect and Create Together</p>
            <p>I am always excited to connect with new people and explore potential collaborations. Whether you have a project in mind, need assistance with design or development, or just want to discuss creative ideas, feel free to reach out. Let's turn your vision into reality.</p>
            <div>
              <img src={contact} alt="contact" className="w-full h-full" />
            </div>
          </div>
          <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md">
            <form onSubmit={sendEmail} className="space-y-5">
              <div>
                <label className="font-medium">Full name</label>
                <input type="text" name="fullName" required className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-purple-700 shadow-sm rounded-lg" />
              </div>
              <div>
                <label className="font-medium">Email</label>
                <input type="email" name="email" required className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-purple-700 shadow-sm rounded-lg" />
              </div>
              <div>
                <label className="font-medium">Subject</label>
                <input type="text" name="subject" required className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-purple-700 shadow-sm rounded-lg" />
              </div>
              <div>
                <label className="font-medium">Message</label>
                <textarea name="message" required className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-purple-700 shadow-sm rounded-lg"></textarea>
              </div>
              <button className="w-full px-4 py-2 text-white font-medium bg-purple-700 hover:bg-purple-500 active:bg-purple-700 rounded-lg duration-150">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
