import { useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import logo from "../assests/services/logo.svg";
import ppt from "../assests/services/ppt.svg";
import video from "../assests/services/video.svg";
import ui from "../assests/services/userflow.svg";
import ux from "../assests/services/ux.svg";
import graphic from "../assests/services/graphics.svg";

const servicesData = {
  title: "Services",
  subtitle:
    "Offering a range of design and development services to bring ideas to life.",
  features: [
    {
      title: "UI Design",
      description:
        "Specializing in intuitive, visually appealing user interfaces for seamless experiences across all platforms.",
      image: ui,
      alt: "UI Design illustration",
      read_more_link: "#",
    },
    {
      title: "UX Design",
      description:
        "Focusing on user needs and behaviors to create meaningful, impactful experiences.",
      image: ux,
      alt: "UX Design illustration",
      read_more_link: "#",
    },
    {
      title: "Graphic Design",
      description:
        "Blending creativity with strategic thinking to produce impactful visuals that convey messages effectively.",
      image: graphic,
      alt: "Graphic Design illustration",
      read_more_link: "#",
    },
    {
      title: "Logo Creation",
      description:
        "Creating unique logos that tell a story and reflect the brand's essence.",
      image: logo,
      alt: "Logo Creation illustration",
      read_more_link: "#",
    },
    {
      title: "PPT Design",
      description:
        "Crafting visually stunning presentations that captivate and convey messages effectively.",
      image: ppt,
      alt: "PPT Design illustration",
      read_more_link: "#",
    },
    {
      title: "Video Editing",
      description:
        "Combining creativity with technical expertise to produce high-quality, engaging videos.",
      image: video,
      alt: "Video Editing illustration",
      read_more_link: "#",
    },
  ],
};

export default function Services() {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    if (direction === "left") {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    } else {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };
  return (
    <>
      {/* component */}
      <div className="py-16 bg-gray-50 overflow-hidden" id="services">
        <div className="container m-auto px-6 space-y-8 text-gray-500 md:px-12">
          <div>
            <span className="text-gray-600 text-lg font-semibold">
              {servicesData.title}
            </span>
            <h2 className="mt-4 text-2xl text-gray-900 font-bold md:text-4xl">
              {servicesData.subtitle.split(" ").map((word, index) =>
                index === 5 ? (
                  <>
                    <br className="lg:block" hidden="" /> {word}{" "}
                  </>
                ) : (
                  ` ${word}`
                )
              )}
            </h2>
          </div>
          <div className="py-16 relative">
  <div className="container m-auto px-6 text-gray-500 md:px-12 xl:px-0 relative flex items-center justify-between">
    <button
      className="p-2 bg-gray-200 dark:bg-gray-700 rounded-full"
      onClick={() => scroll("left")}
    >
      <ChevronLeftIcon className="w-6 h-6 text-gray-800 dark:text-white" />
    </button>
    <div
      ref={scrollRef}
      className="flex overflow-x-scroll scrollbar-hide space-x-6 md:w-3/4 lg:w-full"
    >
      {servicesData.features.map((feature, index) => (
        <div
          key={index}
          className="flex-shrink-0 border border-gray-100 dark:border-gray-700 rounded-3xl bg-white dark:bg-gray-800 px-8 pt-12 shadow-2xl shadow-gray-600/10 dark:shadow-none sm:px-12 lg:px-8"
          style={{ width: "300px" }}
        >
          <div className="mb-12 space-y-4">
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
              {feature.title}
            </h3>
            <p className="mb-6 text-gray-600 dark:text-gray-300">
              {feature.description}
            </p>
            <a
              href={feature.read_more_link}
              className="block font-medium text-primary"
            >
              Know more
            </a>
          </div>
          <img
            src={feature.image}
            className="ml-auto w-2/3"
            alt={feature.alt}
            loading="lazy"
            width="900"
            height="600"
          />
        </div>
      ))}
    </div>
    <button
      className="p-2 bg-gray-200 dark:bg-gray-700 rounded-full"
      onClick={() => scroll("right")}
    >
      <ChevronRightIcon className="w-6 h-6 text-gray-800 dark:text-white" />
    </button>
  </div>
</div>

        </div>
      </div>
    </>
  );
}
