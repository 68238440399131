import React from 'react';
import '../App.css';

export default function PPTs() {
  return (
<>
<div className="w-full h-11 rounded-t-lg bg-gray-900 flex justify-start items-center space-x-1.5 px-3">
    <span className="w-3 h-3 rounded-full bg-red-400" />
    <span className="w-3 h-3 rounded-full bg-yellow-400" />
    <span className="w-3 h-3 rounded-full bg-green-400" />
    <p className="text-white font-bold">Here is some of My PPT Design </p>
  </div>
<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} className='bg-[#313131] p-8 rounded-b-lg'>
      <div style={{ flex: '0 0 33.333%', maxWidth: '33.333%', position: "relative", height: '0', paddingBottom: '56.25%', marginBottom: '20px' }}>
      <h3 style={{ position: "absolute", top: '-25px', left: '50%', transform: 'translateX(-50%)', color: '#fff' }}>Example 1</h3>        
      <iframe 
          src="https://drive.google.com/file/d/1PXnLCkiEwlYIArQUkH1_Ji5h3qrWWfsy/preview" 
          style={{ position: "absolute", top: '0', left: '0', width: '100%', height: '100%' }}
          allow="autoplay"
          sandbox="allow-scripts allow-same-origin"
        ></iframe>
      </div>
      <div style={{ flex: '0 0 33.333%', maxWidth: '33.333%', position: "relative", height: '0', paddingBottom: '56.25%', marginBottom: '20px' }}>
      <h3 style={{ position: "absolute", top: '-25px', left: '50%', transform: 'translateX(-50%)', color: '#fff' }}>Example 2</h3>        
        <iframe 
          src="https://drive.google.com/file/d/1-P-I6anC1j0KNHsgJ62xbjTlyuzArx1m/preview" 
          style={{ position: "absolute", top: '0', left: '0', width: '100%', height: '100%' }}
          allow="autoplay"
          sandbox="allow-scripts allow-same-origin"
        ></iframe>
      </div>
      <div style={{ flex: '0 0 33.333%', maxWidth: '33.333%', position: "relative", height: '0', paddingBottom: '56.25%', marginBottom: '20px' }}>
      <h3 style={{ position: "absolute", top: '-25px', left: '50%', transform: 'translateX(-50%)', color: '#fff' }}>Example 3</h3>        
        <iframe 
          src="https://drive.google.com/file/d/1FSkX9e4LpTHAFJPX6hDMOhyQ3ewVdfPA/preview" 
          style={{ position: "absolute", top: '0', left: '0', width: '100%', height: '100%' }}
          allow="autoplay"
          sandbox="allow-scripts allow-same-origin"
        ></iframe>
      </div>
    </div>
</>
  );
}
