import React from "react";

export default function Videos() {
  function smoothScroll(e) {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href'); 
    const targetElement = document.querySelector(targetId); 
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <section className="">
      <div id="experience" className=" text-black py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full md:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 relative">
            <div className="lg:max-w-xl lg:pr-5 relative z-40">
              <p className="flex text-sm uppercase text-g1">
                Dynamic Visual Content
              </p>
              <h2 className="mb-6 max-w-lg text-4xl font-light leading-snug tracking-tight text-g1 sm:text-6xl lg:text-4xl sm:leading-snug">
                <span className="my-1 inline-block border-b-8 border-g4  px-4 font-bold text-g4 animate__animated animate__flash">
                  Video Editing{" "}
                </span>{" "}
              </h2>
              <p className="text-base text-gray-900">
                In my video editing projects, I focus on creating dynamic and
                engaging visual content. Whether for promotional videos,
                tutorials, or storytelling, each project is edited to enhance
                the overall impact and viewer engagement.
              </p>
              <div className="mt-10 flex flex-col items-center md:flex-row">
                <a
                  href="#contact"
                  onClick={(e) => smoothScroll(e)}
                  aria-label="Get Connect Now"
                  className="group inline-flex items-center font-semibold text-g1"
                >
                  Request a work now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-4 h-6 w-6 transition-transform group-hover:translate-x-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3">
            <div className="container mx-auto w-full h-full">
              <div className="flex flex-wrap justify-between">
                <div className="w-full sm:w-1/2 p-4">
                  <div
                    className="relative overflow-hidden rounded-lg"
                    style={{ paddingTop: "56.25%" }}
                  >
                    <iframe
                      src="https://www.youtube.com/embed/MlRyAZiG2t4"
                      title="YouTube video"
                      className="absolute top-0 left-0 w-full h-full"
                      allow="autoplay; encrypted-media"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p className="mt-2 text-center text-gray-700">
                    Developed a promotional video for a product, showcasing its
                    features and benefits to engage potential users.
                  </p>
                </div>
                <div className="w-full sm:w-1/2 p-4">
                  <div
                    className="relative overflow-hidden rounded-lg"
                    style={{ paddingTop: "56.25%" }}
                  >
                    <iframe
                      src="https://www.youtube.com/embed/7ZEg_XXAKX8"
                      title="Google Drive video"
                      className="absolute top-0 left-0 w-full h-full"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p className="mt-2 text-center text-gray-700">
                    Created an explainer video for a project, detailing its
                    objectives, process, and outcomes to inform and educate
                    viewers.
                  </p>
                </div>
                <div className="w-full sm:w-1/2 p-4">
                  <div
                    className="relative overflow-hidden rounded-lg"
                    style={{ paddingTop: "56.25%" }}
                  >
                    <iframe
                      src="https://www.youtube.com/embed/Rx2-sxyCDSE"
                      title="Another YouTube video"
                      className="absolute top-0 left-0 w-full h-full"
                      allow="autoplay; encrypted-media"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p className="mt-2 text-center text-gray-700">
                    Produced a tutorial video demonstrating a specific feature
                    of my project, aimed at helping users understand and utilize
                    it effectively.
                  </p>
                </div>
                <div className="w-full sm:w-1/2 p-4">
                  <div
                    className="relative overflow-hidden rounded-lg"
                    style={{ paddingTop: "56.25%" }}
                  >
                    <iframe
                      src="https://www.youtube.com/embed/mkCP31TGiuI"
                      title="Google Drive video"
                      className="absolute top-0 left-0 w-full h-full"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <p className="mt-2 text-center text-gray-700">
                    Created Promo video for GenAI.Connect event which organised
                    in Salem, Tamil Nadu by StartNet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
