import { useState } from "react";
import NearTreatLogo from "../assests/logos/neartreat.png";
import QRMedisyncLogo from "../assests/logos/qrmed.png";
import TimelyTaskLogo from "../assests/logos/timelytask.png";
import StuTrackLogo from "../assests/logos/stutrack.png";
import SalesIQ from "../assests/logos/saleiq.png";
import Leaflet from "../assests/logos/leaflet.png";
import NodeJS from "../assests/logos/Node JS.svg";
import ReactJS from "../assests/logos/React js.webp";
import ExpressJS from "../assests/logos/Express JS.png";
import MongoDB from "../assests/logos/MongoDB.png";
import Firebase from "../assests/logos/Firebase.jpg";
import TailwindCSS from "../assests/logos/Tailwind CSS.png";
import CanvasJS from "../assests/logos/Canvas JS.png";
import Arduino from "../assests/logos/Arduino.png";
import JustPromptLogo from "../assests/logos/JustPromptLogo.png";


const techIconMapping = {
  "React JS": { icon: ReactJS },
  "Node JS": { icon: NodeJS },
  "Express JS": { icon: ExpressJS },
  MongoDB: { icon: MongoDB },
  Firebase: { icon: Firebase },
  "Tailwind CSS": { icon: TailwindCSS },
  "Canvas JS": { icon: CanvasJS },
  Arduino: { icon: Arduino },
  "Zoho SalesIQ": { icon: SalesIQ },
  "React Leaflet": { icon: Leaflet },
};

const WebDevProjects = () => {
  const [projectData] = useState([
    {
      title: "NearTreat",
      role: "Co-founder and CEO",
      description:
        "Bridging the gap between street food vendors and food lovers. Developed and led a team to create the NearTreat web application, a revolutionary solution addressing the challenge of locating street food vendors. The app provides live location tracking and real-time availability of street food recipes, enhancing the street food experience for both sellers and buyers.",
      linkText: "Product Link",
      link: "https://www.neartreat.in",
      videoLink: "https://youtu.be/pF5D-Y5qYAc",
      domain: "Web development using MERN Stack",
      techStack: [
        "React JS",
        "Node JS",
        "Express JS",
        "MongoDB",
        "Firebase",
        "Zoho SalesIQ",
        "Tailwind CSS",
        "React Leaflet",
      ],
      logo: NearTreatLogo,
    },
    {
      title: "QRMedisync",
      description:
        "Designed and implemented a QR code-based drug dispensing system using a combination of embedded systems and web technologies. Patients scan their prescription QR code through a user-friendly interface, triggering the system to verify the medication and dispense it safely through a servo-controlled mechanism.",
      linkText: "Project Link",
      link: "https://qrmedisync.mahsook.tech/",
      domain: "Embedded Systems and IoT",
      techStack: [
        "React JS",
        "Node JS",
        "Express JS",
        "Tailwind CSS",
        "Canvas JS",
        "Arduino",
      ],
      logo: QRMedisyncLogo,
      videoLink: "https://youtu.be/1J9Z9J9Z9J9Z",
    },
    {
      title: "TimelyTask",
      description:
        "The TimelyTask project is a Web that aims to simplify task management and payment for blue-collar workers. Assign, receive, and complete tasks through the Web Application. Automatic payment of wages once the task is completed. User-friendly interface and easy login process for efficient task and financial management.",
      linkText: "Project Link",
      link: "https://timelytask.mahsook.tech/",
      domain: "Web development using MERN Stack",
      techStack: [
        "React JS",
        "Node JS",
        "Express JS",
        "MongoDB",
        "Firebase",
        "Tailwind CSS",
        "React Leaflet",
      ],
      logo: TimelyTaskLogo,
      videoLink: "https://youtu.be/Rx2-sxyCDSE",
    },
    {
      title: "StuTrack",
      description:
        "Tool that tracks the number of coding problems solved by each student in my department. Platform for Tracking Student Performance in Competitive Programming Websites Such as LeetCode, CodeChef, Codeforces, and HackerRank.",
      linkText: "Project Link",
      link: "https://stutrack.mahsook.tech/",
      domain: "Web development",
      techStack: [
        "React JS",
        "Node JS",
        "Express JS",
        "Tailwind CSS",
        "Canvas JS",
      ],
      logo: StuTrackLogo,
      videoLink: "https://youtu.be/7ZEg_XXAKX8",
    },
    {
      title: "JustPrompt",
      description: "Transform Offline Businesses into Online Success",
      linkText: "Project Link",
      link: "https://justprompt.vercel.app/",
      domain: "Web development",
      techStack: [
        "React JS",
        "Node JS",
        "Express JS",
        "Tailwind CSS",
        "Canvas JS",
      ],
      logo: JustPromptLogo,
      videoLink: "",
    },
  ]);

  function smoothScroll(e) {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href'); 
    const targetElement = document.querySelector(targetId); 
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <section className="">
      <div id="experience" className=" text-black py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full md:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 relative">
            <div className="lg:max-w-xl lg:pr-5 relative z-40">
              <p className="flex text-sm uppercase text-g1">Web Development</p>
              <h2 className="mb-6 max-w-lg text-4xl font-light leading-snug tracking-tight text-g1 sm:text-6xl lg:text-4xl sm:leading-snug">
                <span className="my-1 inline-block border-b-8 border-g4  px-4 font-bold text-g4 animate__animated animate__flash">
                  MERN Stack{" "}
                </span>{" "}
              </h2>
              <p className="text-base text-gray-900">
                My web development projects utilizing the MERN stack (MongoDB,
                Express.js, React.js, Node.js) showcase my ability to create
                robust and scalable web applications. Discover how I build
                seamless, high-performing applications from front-end to
                back-end, addressing complex business requirements and
                delivering optimal user experiences.
              </p>
              <div className="mt-10 flex flex-col items-center md:flex-row">
                <a
                  href="#contact"
                  onClick={(e) => smoothScroll(e)}
                  aria-label="Get Connect Now"
                  className="group inline-flex items-center font-semibold text-g1"
                >
                  Request a work now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-4 h-6 w-6 transition-transform group-hover:translate-x-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3">
            <div className="container mx-auto w-full h-full">
              <div className="flex flex-wrap justify-center pb-10">
                {projectData.map((project, index) => (
                  <div className="w-full sm:w-1/2 lg:w-1/2 p-4" key={index}>
                    {" "}
                    {/* Adjusted padding for better spacing */}
                    <div className="flex flex-col h-full rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300">
                      {" "}
                      {/* Consistent shadow and rounded corners */}
                      <img
                        className="w-full h-48 object-contain rounded-t-lg"
                        src={project.logo}
                        alt={project.title}
                      />{" "}
                      {/* Consistent height for images */}
                      <div className="flex-grow p-6 flex flex-col">
                        <h2 className="font-bold text-xl md:text-2xl mb-2 text-gray-800">
                          {project.title}
                        </h2>{" "}
                        {/* Consistent text color */}
                        <p className="text-gray-700 text-base mb-4 leading-snug">
                          {project.description.slice(0, 100)}
                        </p>{" "}
                        {/* Simplicity and whitespace */}
                        <div className="flex flex-wrap justify-start items-center space-x-2 mb-4">
                          {" "}
                          {/* Improved spacing for icons */}
                          {project.techStack.map((tech, idx) => (
                            <img
                              key={idx}
                              className="h-10 w-10 rounded-full ring-2 bg-white ring-gray-200 p-1 object-contain" // Consistent size for icons
                              src={techIconMapping[tech]?.icon}
                              alt={tech}
                            />
                          ))}
                        </div>
                        <div className="mt-auto flex justify-between items-center">
                          {" "}
                          {/* Ensuring buttons are aligned */}
                          <a
                            href={project.link}
                            className="bg-[#8000FF] hover:bg-[#9812FF] text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                          >
                            {project.linkText}
                          </a>
                          {project.videoLink && (
                            <a
                              href={project.videoLink}
                              className="text-[#FF8660] hover:text-[#FF5635] font-bold transition-colors duration-200"
                            >
                              Watch Video
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebDevProjects;
