import React from "react";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import MyWorks from "./components/MyWorks";
import Experience from "./components/Experience";
import Services from "./components/Services";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Achievement from "./components/Achievement";

export default function App() {
  return (
    <>
    <Navbar />
    <Hero />
    <About />
    <Experience />
    <Services />
    <Projects />
    <Achievement />
    <Contact />
    <Footer />
    </>
  )
}